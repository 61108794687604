export const tasksFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        status: true,
        search: true
    },
    initValues: {
        status: 'all'
    },
    lists: {
        fieldsList: [
            {
                value: 'subject',
                text: 'Name'
            },
            {
                value: 'description',
                text: 'Description'
            }
        ],
        statusList: [
            {
                value: 'all',
                text: 'All'
            },
            {
                value: 1,
                text: 'Draft'
            },
            {
                value: 2,
                text: 'New'
            },
            {
                value: 3,
                text: 'In progress'
            },
            {
                value: 4,
                text: 'In review'
            },
            {
                value: 5,
                text: 'Feedback'
            },
            {
                value: 6,
                text: 'Reopen'
            },
            {
                value: 7,
                text: 'Done'
            }
        ]
    }
}
