export const FilterItems = [
    {
        name: 'users-filter',
        label: 'Executor',
        value: 'assignee_id'
    },
    {
        name: 'status',
        value: 'status'
    },
    {
        name: 'search-field',
        value: 'search_by'
    },
    {
        name: 'search-query',
        value: 'search_for'
    },
    {
        name: 'pagination-page',
        value: 'page'
    },
    {
        name: 'per-page',
        value: 'per_page'
    }
]

export const Filters = {
    status: 'all',
    search_by: 'subject',
    search_for: '',
    page: '1',
    per_page: '10',
    sort_by: 'updated_at',
    sort_order: 'DESC'
}
