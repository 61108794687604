export const toolbarSettings = [
    {
        name: 'delete',
        permission: 'content/delete'
    },
    {
        name: 'priority',
        permission: 'content/delete'
    },
    {
        name: 'status',
        permission: 'content/delete'
    },
    {
        name: 'TaskCategory',
        permission: 'content/delete'
    },
    {
        name: 'comment',
        permission: 'content/publish'
    },
    {
        name: 'TakeTask',
        permission: 'content/publish'
    },
    {
        name: 'FinalizeTask',
        permission: 'content/publish'
    },
    {
        name: 'complete',
        permission: 'content/publish'
    }
]
