<template>
    <custom-table
        :filter_settings="tasksFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :loading="loading || !tasks"
        :content-type="contentType"
        :items="tasks"
        :toolbar_settings="toolbarSettings"
        :options="tableOptions"
        @selectAllCheckbox="selectAllCheckbox($event)"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/tasks-page/content-table-header-items'
import { FilterItems, Filters } from '@/helpers/constants/tasks-page/filters'
import { tasksFilters } from '@/helpers/filters-settings/tasks-filters'
import { toolbarSettings } from '@/helpers/constants/tasks-page/toolbar-settings'

import {
    ACTION_GET_TASKS_LIST,
    ACTION_GET_TASKS_STATUSES,
    ACTION_GET_TASKS_PRIORITIES
} from '@/store/modules/tasks/action-types'

import { mapTasksFields } from '@/store/modules/tasks'

import { createNamespacedHelpers } from 'vuex';
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapTasksActions
} = createNamespacedHelpers('tasks');

export default {
    name: 'IndexVue',
    mixins: [
        contentBaseMixin
    ],
    props: {
        contentType: String,
        classes: String
    },
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-tasks',
            filters: {}
        }
    },
    computed: {
        ...mapTasksFields([
            'tasks',
            'statuses',
            'priorities'
        ]),
        allToolbarSettings() {
            return toolbarSettings
        },
        tasksFilters() {
            return tasksFilters
        },
        headerItems() {
            return headerItems
        },
        filterItems() {
            return FilterItems
        },
        items() {
            return this.tasks
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(function (val) {
            if (this.$route.name !== 'posts-list-tasks') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    async activated() {
        if (!this.statuses) {
            await this[ACTION_GET_TASKS_STATUSES]()
        }
        if (!this.priorities) {
            await this[ACTION_GET_TASKS_PRIORITIES]()
        }
        this.getList(this.filters)
    },
    methods: {
        ...mapTasksActions([
            ACTION_GET_TASKS_LIST,
            ACTION_GET_TASKS_STATUSES,
            ACTION_GET_TASKS_PRIORITIES
        ]),
        getList(query) {
            if (query.status === 'all') {
                delete query.status
            }
            this[ACTION_GET_TASKS_LIST]({ ...query })
        }
    }
}
</script>
