export const headerItems = [
    {
        sort_by: 'checkbox',
        styles: 'width:35px;'
    },
    {
        sort_by: 'id',
        title: 'ID',
        styles: 'min-width:35px; width: 3%; text-align: left;'
    },
    {
        sort_by: 'subject',
        title: 'Name',
        styles: 'min-width:220px; width: 18%; text-align: left;'
    },
    {
        sort_by: 'priority',
        title: 'Pr-t',
        styles: 'min-width:50px; width: 3%; text-align: left;'
    },
    {
        sort_by: 'status',
        title: 'Status',
        styles: 'min-width:80px; width: 6%; text-align: left;'
    },
    {
        sort_by: 'post_type',
        title: 'Category',
        styles: 'min-width:80px; width: 5%; text-align: left;',
        hide_sort: true
    },
    // {
    //     sort_by: 'locale',
    //     title: 'Loc.',
    //     styles: 'min-width:70px; width: 8%; text-align: left;'
    // },
    {
        sort_by: 'created_at',
        title: 'Created',
        styles: 'min-width:80px; width: 8%; text-align: left;'
    },
    {
        sort_by: 'deadline_at',
        title: 'Due date',
        styles: 'min-width:50px; width: 5%; text-align: left;'
    },
    {
        sort_by: 'comments_count',
        title: 'Comments',
        styles: 'min-width:80px; width: 5%; text-align: left;'
    },
    {
        sort_by: 'assagnee',
        title: 'Executor',
        styles: 'min-width:65px; width: 5%; display: flex; justify-content: center;',
        hide_sort: true
    },
    {
        sort_by: 'owner',
        title: 'By',
        styles: 'min-width:65px; width: 5%; display: flex; justify-content: center;',
        hide_sort: true
    }
]
